import React,{useState,useEffect} from 'react';
import BasicBarChart from '../../../Components/highCharts/basicBarChart'
import {getShopType} from '../../../Services/graphs-services/graphs-services'
import Loader from '../../../Components/loader/loader'

const ShopType = ({title,selected}) => {
    const [loading,setLoading]=useState(true)
    const [data,setData]=useState()

    useEffect(()=>{
        fetchShopType()
    },[])

    const fetchShopType=async()=>{
        const res=await getShopType(selected)
        if(res){
            setData(mapDataForGraph(res))
            setLoading(false)
        }
    }

    const mapDataForGraph=(list)=>{
        let tempList=[]
        list.forEach(item=>{  
            if(item.storeType!='0'){
                let temp={}
                temp['name']=item.storeType
                temp['data']=[item.count]
                tempList.push(temp)
            }
           
        })
        return tempList
    }

    return (
        <div>
            {
                loading?
                <Loader/>
                :
                data &&
                <BasicBarChart title={title} data={data}/>
            }
        </div>
    );
}

export default ShopType;