import React,{useState,useEffect} from 'react';
import BasicBarChart from '../../../Components/highCharts/basicBarChart'
import {getInterviewStatus} from '../../../Services/graphs-services/graphs-services'
import Loader from '../../../Components/loader/loader'

const InterviewStatuses = ({title,selected}) => {
    const [data,setData]=useState()
    const [loading,setLoading]=useState(true)

    useEffect(()=>{
        fetchInterviewStatus()
    },[])

    const fetchInterviewStatus=async()=>{
        const res=await getInterviewStatus(selected)
        if(res){
            setData(mapDataForGraph(res))
            setLoading(false)
        }
    }

    const mapDataForGraph=(list)=>{
        let tempList=list.map(item=>{
            let temp={}
            temp['name']=item.shopStatus
            temp['data']=[item.count]
            return temp
        })
        return tempList
    }
    

    return (
        <div>

            {
                loading?
                <Loader/>
                :
                data && 
                <BasicBarChart title={title} data={data}/>
            }
            
        </div>
    );
}

export default InterviewStatuses;