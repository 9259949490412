import React from "react";
import Loader from "react-loader-spinner";
import { Grid } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import theme from "../../theme";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)'
// }

const Spinner = ({ height, width, color, type }) => {
  const LoaderColor = theme.palette.primary.main;

  return (
    <Grid container justify="center" alignItems="center">
      <div>
        <Loader
          visible={true}
          type={type || "TailSpin"}
          color={LoaderColor}
          // color={color || '#de9631'}
          height={height || 100}
          width={width || 100}
        />
      </div>
    </Grid>
  );
};

export default Spinner;
