import React, { useEffect, useState } from "react";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Container from "@material-ui/core/Container";
require("highcharts/modules/exporting")(Highcharts);

const getColor = () => {
  return `rgb(${getRGB()}, ${getRGB()}, ${getRGB()})`;
};
const getRGB = () => Math.round(Math.random() * 255);
const BasicColumnChart = (props) => {
  const getColors = () => {
    const colors = [];
    for (let i = 0; i < 100; i++) colors.push(getColor());
    return colors;
  };
  let chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: props.title||'',
    },
    // subtitle: {
    //   text: "Source: WorldClimate.com",
    // },
    xAxis: {
      categories: [
        "PSO",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{this.name}: </td>' +
        '<td style="padding:0"><b>{point.y} Count</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Tokyo",
        data: [
          49.9,
          71.5,
          106.4,
          129.2,
          144.0,
          176.0,
          135.6,
          148.5,
          216.4,
          194.1,
          95.6,
          54.4,
        ],
      },
      {
        name: "New York",
        data: [
          83.6,
          78.8,
          98.5,
          93.4,
          106.0,
          84.5,
          105.0,
          104.3,
          91.2,
          83.5,
          106.6,
          92.3,
        ],
      },
      {
        name: "London",
        data: [
          48.9,
          38.8,
          39.3,
          41.4,
          47.0,
          48.3,
          59.0,
          59.6,
          52.4,
          65.2,
          59.3,
          51.2,
        ],
      },
      {
        name: "Berlin",
        data: [
          42.4,
          33.2,
          34.5,
          39.7,
          52.6,
          75.5,
          57.4,
          60.4,
          47.6,
          39.1,
          46.8,
          51.1,
        ],
      },
    ],
  };
  const { data, categories, title } = props;
  const [state, setstate] = useState(null);
  console.log("DataColumn", data);
  console.log("categoriesDataColumn", categories);
  useEffect(() => {
    //     let obj = [];
    //     obj.push({
    //       name: title,
    //       data,
    //     });
    chartOptions.series = data;
    chartOptions.colors = getColors();
    chartOptions.title.text = title;
    chartOptions.xAxis.categories = categories;
    setstate(chartOptions);
  }, []);
  return (
    <Container>
      {state && <HighchartsReact highcharts={Highcharts} options={state} />}
    </Container>
  );
};
export default BasicColumnChart;
