import React,{useState,useEffect} from 'react';
import BasicColumnChart from '../../../Components/highCharts/basiccolumn'
import {getSegmentWiseBrandAvailability} from '../../../Services/graphs-services/graphs-services'
import Loader from '../../../Components/loader/loader'


const SegmentWiseBrandAvailability = ({title,selected}) => {
    const [data,setData]=useState()
    const [categories,setCategories]=useState()
    const [loading,setLoading]=useState(true)

    const dat=[{
        name: 'PCO',
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]

      }, {
        name: 'MCO',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

    }, {
        name: 'DEO',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

    }]
    

    useEffect(()=>{
        fetchSegmentWiseBrandAvailability()
    },[])

    const fetchSegmentWiseBrandAvailability=async()=>{
        const res=await getSegmentWiseBrandAvailability(selected)
        if(res){
            setData(mapDataForGraph(res))
            setLoading(false)
        }
    }

    const mapDataForGraph=(list)=>{
        let tmpList=Object.entries(list)
        console.log(tmpList)
        let tempList=[]
        let PCO=[]
        let MCO=[]
        let DEO=[]
        let tempCategories=[]
        tmpList.forEach(item=>{
            tempCategories.push(item[0])
            item[1].forEach(item1=>{
              console.log(item1)
              if(item1?.PCO){
                PCO.push(item1.PCO)
              }
              if(item1?.MCO){
                MCO.push(item1.MCO)
              }
              if(item1?.DEO){
                DEO.push(item1.DEO)
              }
            })
        })
        tempList=[{name:'PCO',data:PCO},{name:'MCO',data:MCO},{name:'DEO',data:DEO}]
        setCategories(tempCategories)
        console.log(tempCategories)
        console.log(tempList)
        return tempList
    }


    return (
        <div>
           {loading?
                <Loader/>
                :
                data && categories && <BasicColumnChart data={data} title={title} categories={categories}/>}
        </div>
    );
}

export default SegmentWiseBrandAvailability;