import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: "#2196f3",
      main: "#4caf50",
      // main: "#FFB319",
      light: "#9f5fb0",
    },
    secondary: {
      main: "#FB9902",
      // main: "#410153",
      // light: "#E0E0E0",
    },
  },
  typography: {
    // subtitle1:{
    //   font:'16px Roboto Medium'
    // },
    // subtitle2:{
    //   font:'14px Roboto Medium'
    // },
    // h1:{
    //   font:'96px Roboto Light'
    // },
    // h2:{
    //   font:'60px Roboto Light'
    // },
    // h3:{
    //   font:'48px Roboto Regular'
    // },
    // h4:{
    //   font:'34px Roboto Regular'
    // },
    // h5:{
    //   font:'24px Roboto Regular'
    // },
    // h6:{
    //   font:'20px Roboto Medium'
    // },
    // body1:{
    //   font:'16px Roboto Regular'
    // },
    // body2:{
    //   font:'14px Roboto Regular'
    // },
    // overline:{
    //   font:'10px Roboto Regular Caps'
    // },
    // caption:{
    //   font:'12px Roboto Regular'
    // },
    // button:{
    //   font:'12px Roboto Medium Caps'
    // },
    fontFamily: [
      "Montserrat",
      "sans-serif",
      // 'Roboto','sans-serif'
    ].join(","),
  },
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
