import React,{useState,useEffect} from 'react';
import BasicBarChart from '../../../Components/highCharts/basicBarChart'
import {getSourceOfPurchase} from '../../../Services/graphs-services/graphs-services'
import Loader from '../../../Components/loader/loader'


const SourceOfPurchase = ({title,selected}) => {
    const [data,setData]=useState()
    const [loading,setLoading]=useState(true)

    useEffect(()=>{
        fetchSourceOfPurchase()
    },[])

    const fetchSourceOfPurchase=async()=>{
        const res=await getSourceOfPurchase(selected)
        console.log(res)
        if(res){
            setData(mapDataForGraph(res))
            setLoading(false)
        }
    }

    const mapDataForGraph=(list)=>{
        console.log(list)
        let tempList=[]
        list.map(item=>{
            if(item.SOP!='0'){
                let temp={}
                temp['name']=item.SOP
                temp['data']=[item.count]
                tempList.push(temp)
            }
            
        })
        return tempList
    }


    return (
        <div>
            {loading?
                <Loader/>
                :data && <BasicBarChart data={data} title={title} />}
        </div>
    );
}

export default SourceOfPurchase;