import React,{useEffect,useState} from 'react';
import BasicBarChart from '../../../Components/highCharts/basicBarChart'
import {getBrandWiseAvailability} from '../../../Services/graphs-services/graphs-services'
import Loader from '../../../Components/loader/loader'

const BrandWiseAvailability = ({title,selected}) => {
    const [data,setData]=useState()
    const [loading,setLoading]=useState(true)


    useEffect(()=>{
        fetchBrandWiseAvailability()
    },[])

    const fetchBrandWiseAvailability=async()=>{
        const res=await getBrandWiseAvailability(selected)
        if(res){
            setData(mapDataForGraph(res))
            setLoading(false)
        }
    }

    const mapDataForGraph=(list)=>{
        let tmpList=Object.entries(list)
        let tempList=tmpList.map(item=>{
            let temp={}
            temp['name']=item[0]
            temp['data']=[item[1]]
            return temp
        })
        return tempList
    }

    return (
        <div>
           { loading?
                <Loader/>
                :
                data && 
                <BasicBarChart title={title} selected={selected} data={data}/>}
        </div>
    );
}

export default BrandWiseAvailability;