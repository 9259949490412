import React, { memo } from "react";
import { TextField, makeStyles } from "@material-ui/core";
// import {  } from "@material-ui/core/styles";
//onChange for handling input change
//value for displaying current value

const useStyles = makeStyles((theme) => ({
  bg: {
    background: "pink",
  },
  resize: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  labelRoot: {
    border: "1px solid red",
  },
  input: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  resize1: {
    fontSize: "13px",
  },
}));

const Input = ({
  error,
  margin,
  type,
  fullWidth,
  style,
  multiline,
  endAdornment,
  size,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <TextField
      size={size || "small"}
      type={type || "value"}
      autoComplete="off"
      margin={margin || "normal"}
      className={classes.input}
      // InputProps={{
      //   classes: {
      //     input: classes.resize1,
      //   },
      // }}
      fullWidth={false || fullWidth}
      multiline={false || multiline}
      {...rest}
    />
    // <div>
    //   {/* {console.log("Input rendered")} */}
    //   <div>

    //   </div>
    //   {error && <Typography className={classes.bg}>{error}</Typography>}
    // </div>
  );
};

export default memo(Input);

// const Input = ({ label, name, error, ...rest }) => {
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <TextField
//                 disabled={disabled}
//                 ref={ref}
//                 id={name}
//                 InputProps={inputProps}
//                 InputLabelProps={inputLabelProps}
//                 onClick={onClick}
//                 // inputProps={inputProps}
//                 type={type||'value'}
//                 style={style}
//                 label={label}
//                 placeholder={placeholder}
//                 className={className}
//                 margin="normal"
//                 helperText={helperText}
//                 fullWidth={false || fullWidth}
//                 multiline={false||multiline}
//                 variant={variant}
//                 rows="5"
//                 onKeyDown={onKeyDown}
//                 onChange={onChange}
//                 value={value}
//             />
//       {/* <input {...rest} id={name} name={name} className="form-control" /> */}
//       {error && <p className="alert alert-danger">{error}</p>}
//     </div>
//   );
// };

// export default Input;
