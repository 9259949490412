import React,{useState,useEffect} from 'react';
import {makeStyles,Grid,Paper} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
//Local Components
import Dropdown from '../../Components/dropdown/Dropdown'
import ButtonComponent from '../../Components/button/Button'
import Header from '../../Components/header/Header'

//Graphs
import InterviewStatuses from './Graphs/InterviewStatuses'
import ShopType from './Graphs/ShopType'
import AverageSalesInLitre from './Graphs/AverageSalesInLitre'
import AverageSalesInPKR from './Graphs/AverageSalesInPKR'
import BrandWiseAvailability from './Graphs/BrandWiseAvailability'
import SegmentWiseBrandAvailability from './Graphs/SegmentWiseBrandAvailability'
import SourceOfPurchase from './Graphs/SourceOfPurchase'


//Services
import {getDataOfFilter} from '../../Services/filter-services'

//Styles Declaration
const useStyles = makeStyles((theme) => ({
    main:{
        padding:theme.spacing(5)
    },
    dropdownWidth:{
        width:'40%',
        [theme.breakpoints.down('sm')]: {
            width:'90%',
        },
    },
    paddR:{
        paddingRight:theme.spacing(1)
    },
    paddL:{
        paddingLeft:theme.spacing(2)
    },
    button:{
        width:200
    },
    paper:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3)
    }
}));


const Dashboard = () => {
    const classes = useStyles();
    const [selected,setSelected]=useState()
    const history=useHistory()
    const [data,setData]=useState()
    const [error,setError]=useState()
    const [loading,setLoading]=useState(false)
    const [showGraphs,setShowGraphs]=useState(false)

    useEffect(()=>{
        if(!window.localStorage.getItem('auth_token_custom_dashboards')){
            history.push('/')
        }
    },[])

    const filterList=[
        {
            id:1,
            name:'Overall'
        },
        {
            id:2,
            name:'East'
        },
        {
            id:3,
            name:'West'
        },
        {
            id:4,
            name:'South'
        },
        {
            id:5,
            name:'Central'
        },
        {
            id:6,
            name:'Korangi'
        },
        {
            id:7,
            name:'Malir'
        },
        {
            id:8,
            name:'Keemari'
        },
        {
            id:9,
            name:"Hub"
        },
        {
            id:10,
            name:'National Highway'
        },
        {
            id:11,
            name:'Super Highway'
        }
    ]

    const handleDropdown=(e)=>{
        setSelected()
        setShowGraphs(false)
        let {value} = e.target
        setSelected(value)
    }

    const getDataOfSelectedFilter=async()=>{
        setShowGraphs(true)
    //     setLoading(true)
    //     // setData([{
    //     //     name: 'Year 1800',
    //     //     data: [107, 31, 635, 203, 2]
    //     // }, {
    //     //     name: 'Year 1900',
    //     //     data: [133, 156, 947, 408, 6]
    //     // }, {
    //     //     name: 'Year 2000',
    //     //     data: [814, 841, 3714, 727, 31]
    //     // }, {
    //     //     name: 'Year 2016',
    //     //     data: [1216, 1001, 4436, 738, 40]
    //     // }])
    //     // getDataOfFilter(selected)
    //     // .then(res=>{
    //     //     setData(res)
    //     //     setLoading(false)
    //     // })
    //     // .catch(err=>{
    //     //     setError(err)
    //     //     setLoading(false)
    //     // })
    }

    const handleClear=()=>{
        setSelected() 
        setShowGraphs(false)
        setData()
    }

    return (
        <>
        <Header title='PSO BOOST DASHBOARD'/>
        <div className={classes.main}>
            <Grid container alignItems="center" direction="column">
                <div className={classes.dropdownWidth}>
                    <Dropdown disable={loading} onChange={handleDropdown} placeholder="Districts" label="Districts" name="selectedFilter" selected={selected} options={filterList} fullWidth color="primary"/>
                </div>
                {
                    selected &&
                    <Grid container>
                        <Grid container xs={6} justify="flex-end" className={classes.paddR}>
                            <ButtonComponent size="large" classname={classes.button} disabled={loading} color="primary" variant="contained" onClick={getDataOfSelectedFilter}>Submit</ButtonComponent>
                        </Grid>
                        <Grid container xs={6} justify="flex-start" className={classes.paddL}>
                            <ButtonComponent size="large" classname={classes.button} disabled={loading} color="primary" variant="outlined" onClick={handleClear}>Clear</ButtonComponent>
                        </Grid>
                    </Grid>
                }
            </Grid>
           
           {
               showGraphs && selected &&
               <Grid container justify="space-between">
                   <Grid container xs={12} sm={6} justify="center">
                       <Paper elevation={3} className={classes.paper}>
                            <InterviewStatuses title="Interview Statuses" selected={filterList.find(item=>item.id==selected).name}/>
                       </Paper>
                   </Grid>
                   <Grid container xs={12} sm={6} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <ShopType title="Shop Types" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid>
                   {/* <Grid container xs={12} sm={6} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <AverageSalesInLitre title="Average Sales In Litres" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid>
                   <Grid container xs={12} sm={6} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <AverageSalesInPKR title="Average Sales In PKR" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid> */}
                   <Grid container xs={12} sm={6} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <BrandWiseAvailability title="Brand Wise Availability" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid>
                   <Grid container xs={12} sm={6} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <SegmentWiseBrandAvailability title="Segment Wise Brand Availability" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid>
                   <Grid container xs={12} justify="center">
                        <Paper elevation={3} className={classes.paper}>
                            <SourceOfPurchase title="Source Of Purchase" selected={filterList.find(item=>item.id==selected).name}/>
                        </Paper>
                   </Grid>
               </Grid>
           }
            
        </div>      
        </>  
    );
}

export default Dashboard;