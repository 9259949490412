//This component will log in and this is a part of core framework
import React,{useState,useEffect} from 'react';

import {Grid,Typography,Paper,makeStyles} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import img2 from '../../assets/images/img2.png'
import ButtonComponent from '../../Components/button/Button'
import Input from '../../Components/input/Input'
import Error from '../../Components/error/error'
import Spinner from '../../Components/loader/loader'

// import {createCompany} from '../../Services/company-services'

//Styles definition
const useStyles = makeStyles((theme) => ({
    box:{
        height:'100vh',
        // width:'100%',
        
    },
    width:{
        minHeight:'300px',
        width:'20%',
        padding:theme.spacing(10),
    },
    width1:{
        // backdropFilter:'blur(10px)',
        // background: 'grey',
        // WebkitBackdropFilter:'blur(10px)',
        // backdropFilter:`blur(6px)`,
        // filter:'blur(10px)',
        // background:'rgba(0,0,0, 0.2)',
        minHeight:'200px',
        width:'50%',
        padding:theme.spacing(10),
    },
    div:{
        height:'100%',
        width:'100%',
        background:'url(../../assets/images/login.png)',
        backgroundSize:'cover'
    },
    margB:{
        marginBottom:theme.spacing(3)
    },
    divider:{
        padding:0.2,
        margin:theme.spacing(2,0,2,0)
    },
    timeline: {
        transform: "rotate(90deg)"
    },
    timelineIcon: {
        transform: "rotate(-90deg)"
    },
    img:{
        // background:'url(../../assets/images/login.png)',
        // height:'100%',
        // width:'100%',
        // backgroundSize:'cover'
    }
}));

const Login = (props) => {
    const classes=useStyles()
    const history=useHistory()
    const [username,setUsername]=useState(null)
    const [password,setPassword]=useState(null)
    const [error,setError]=useState({})
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        if(!error){
            console.log(username,password)
            window.localStorage.setItem('auth_token_custom_dashboards','login')
            history.push('/dashboard')
        }
        else{
            console.log(error)
        }
    },[error])

    const handleSubmit=()=>{
        if(username==='admin' && password==='admin'){
            setError(null)
            console.log(username, password)
            // history.push('/dashboard')
        }
        // else if(username==='pbc' && password==='pbc123'){
        //     setError(null)
        // }
        else{
            setError({username:'username or password is invalid',password:'username or password is invalid'})
        }
        
    }

    return (
       
        <Grid container className={classes.box} 
            // style={{height:'100%',width:'100%',backgroundImage:`url(${require('../../assets/images/login.png')})`,backgroundSize:'100% 100%'}}
            alignItems="center" >
            <Grid container justify="center" alignItems="center" xs={7}>
                    <img height="500px" src={img2}/>
            </Grid>
            <Grid container xs={5} justify="center" alignItems="center" className={classes.box}>
                <Paper elevation={3} className={classes.width1}>  
                    {
                    <form onSubmit={handleSubmit}>    
                        <Typography variant="h4" gutterBottom style={{color:'#003300'}}><b>Login</b></Typography>            
                        <Input placeholder="Username" fullWidth value={username} variant="outlined" id="username" label="Username" onChange={(e)=>setUsername(e.target.value)}/>
                        {error && error.username && <Error text={error.username} /> }

                        <Input placeholder="Password" type='password' fullWidth value={password} variant="outlined" id="password" label="Password" onChange={(e)=>setPassword(e.target.value)}/>
                        {error && error.password && <Error text={error.password} /> }

                        <ButtonComponent color="primary" disabled={loading} style={{width:'100%'}} variant="contained" onClick={handleSubmit}>{loading?<Spinner height={10} type={'ThreeDots'}/>:<Typography style={{color:'white'}}>Login</Typography>}</ButtonComponent>
                        {error && error.exist && <Error text={error.exist} /> }
                    </form>
                }
            </Paper>
            </Grid>
        </Grid>
    );
}

export default Login;