import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
 


const BasicBarChart = ({data,categories,title}) => {
    console.log(data)
    console.log(categories)
    const options = {
        chart: {
              type: 'column'
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count'
            }
        },
        plotOptions:{
            column:{
                pointPadding:0.2,
                borderWidth:0
            }
        },
        title: {
          text: title||''
        },
        series:data
      }

    return (
       <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
      </div>
    );
}

export default BasicBarChart;