import React from 'react';
import {Switch,Route} from 'react-router-dom'
import Login from './Screens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import ProtectedRoute from './ProtectedRoute/ProtectedRoute'

const Router = () => {
    return (
        <Switch>
            <Route exact path="/" component={Login}/>
            <Route path="/dashboard" component={Dashboard}/>
            {/* <ProtectedRoute path="/map" component={Map}/> */}
        </Switch>
    );
}

export default Router;