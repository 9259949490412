import axios from 'axios'
import {url} from '../baseURL'

export const getInterviewStatus=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getInterviewStatuses/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>{
            reject(err.response)
        })
    })
}

export const getShopType=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getShopType/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}

export const getBrandWiseAvailability=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getBrandWiseAvailability/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}

export const getSegmentWiseBrandAvailability=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getSegmentWiseBrandAvailability/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}

export const getSourceOfPurchase=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getSourceOfPurchase/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}

export const getSalesInPKR=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getSalesInPKR/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}

export const getSalesInLTR=(key)=>{
    return new Promise((resolve,reject)=>{
        axios.get(`${url}/psoBoost/getSalesInLTR/${key}`)
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>reject(err.response))
    })
}
